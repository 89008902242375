import React from "react"

const ContentfulDivider = ({ section }) => (
	<div>
		<section className="m-basic-content">
			<div className="container">
				<div className="row">
					<div className="s-page col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
					</div>
				</div>
			</div>
		</section>
	</div>
)

export default ContentfulDivider
