import React from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const ContentfulTextWithImageOnTheLeftSimple = ({ section }) => (
	<div>
		<section className="m-image-wc">
			<div className="container">
				<div className="row">
					<div className="col-12 col-sm-6 h-text-align-center">
						<div className="m-image-wc__image m-image-wc__image--top">
							<img className="b-lazy b-loaded" alt="two people looking at computer" src={section.image.file.url}/>
						</div>
					</div>
					<div className="m-image-wc__content col-12 col-sm-5 offset-sm-1">
						<header>
							<div className="scroll-animation scroll-animation__ease-in-from-bottom active">
								<h2 className="a-si h-text-bold">
									{section.smallTitle}
								</h2>
							</div>
							<div className="scroll-animation scroll-animation__ease-in-from-bottom active">
								<h3 className="a-title-40 ">
									{section.title}
								</h3>
							</div>
						</header>
						<div className="col-12 col-sm-6 h-text-align-center">
							<img src={section.image.file.url} alt="two people looking at computer" className="m-image-wc__image m-image-wc__image--middle"/>
						</div>
						<div className="scroll-animation scroll-animation__ease-in-from-bottom active">
							<div className="a-text-20">
								<p dir="ltr">
									{documentToReactComponents(section.richText.json)}
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
)

export default ContentfulTextWithImageOnTheLeftSimple
