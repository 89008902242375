import React from "react"

const ContentfulTextWithImageOnTheLeft = ({ section }) => (
	<div>
		<section className="m-image-wc">
			<div className="container">
				<div className="row">
					<div className="col-12 col-sm-6 h-text-align-center">
						<div className="m-image-wc__image m-image-wc__image--top">
							<img className="b-lazy b-loaded" alt={section.image.file.fileName} src={section.image.file.url}/>
						</div>
					</div>
					<div className="m-image-wc__content col-12 col-sm-6 scroll-animation scroll-animation__ease-in-from-bottom active">
						<header>
							<h2 className="a-si h-text-bold">
								<a href="/what-we-do/strategy" title="strategy " className="a-link--wline" target="">
									{section.smallTitle}
								</a>
							</h2>
							<h3 className="a-title-40 ">
								{section.title}
							</h3>
						</header>
							<img className="b-lazy b-loaded" alt={section.image.file.fileName} src={section.image.file.url}/>
						<div className="a-text-20">
							<p>{section.text}</p>
						</div>
						<div className="scroll-animation scroll-animation__list-ease-in-from-bottom active">
							<h4 className="m-image-wc__lists-title h-text-bold">{section.listTitle}</h4>
							<div className="row m-image-wc__lists">
								<div className="col-12" style={{columnCount: 2}}>
									<ul>
									{section.listItems.map((item) => (
										<li className="m-image-wc__list-item">
											{item}
										</li>		
									))}
									</ul>
								</div>
							</div>
						</div>
						<a href={section.buttonSlug} target="" title="Learn more" className="a-bt">
							{section.buttonText}
						</a>
					</div>
				</div>
			</div>
		</section>
	</div>
)

export default ContentfulTextWithImageOnTheLeft
