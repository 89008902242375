import React from "react"
import Img from "gatsby-image"

const ContentfulTextWithImageOnTheRight = ({ section }) => (
	<div>
		<section className="m-content-wi m-content-wi--dark">
			<div className="container">
				<div className="row">
					<div className="col-12 col-sm-5 m-content-wi__col-left">
						<header className="m-content-wi__header scroll-animation scroll-animation__ease-in-from-bottom active">
							<div className="scroll-animation scroll-animation__ease-in-from-bottom active">
								<h3 className="a-title-40 ">
									{section.heading}
								</h3>
							</div>
							<div className="scroll-animation scroll-animation__ease-in-from-bottom active">
								<div className="a-text-20">
									{section.longText.longText}
								</div>
							</div>
              {section.buttonSlug &&
                <a href={section.buttonSlug} target="_self" title={section.buttonText} className="a-bt">
                  {section.buttonText}
                </a>
              }
						</header>
					</div>
					<div className="col-12 col-sm-7">
						<div className="m-content-wi__image">
							<Img fluid={section.image.fluid}/>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
)

export default ContentfulTextWithImageOnTheRight
