import React from "react"
import Img from "gatsby-image"

const ContentfulTextWithMultipleImages = ({ section}) => (
	<div>
		<section className="m-cw3-images m-cw3-images--">
			<div className="container">
				<div className="row">
					<div className="col-12 col-sm-6 offset-sm-3 col-xl-6 offset-xl-3">
						<div className="m-cw3-images__images">
							<span className="m-cw3-images__image m-cw3-images__image--1">
								<span className="m-cw3-images__image-bg h-abs-bg"></span>
								<div className="h-abs-bg bg-video">
									<Img fluid={section.image1.fluid}/>
								</div>
							</span>
						<span className="m-cw3-images__image m-cw3-images__image--2">
							<Img fluid={section.image2.fluid}/>
						</span>
						<span className="m-cw3-images__image m-cw3-images__image--3">
							<Img fluid={section.image3.fluid}/>
						</span>
					</div>
					<header className="m-cw3-images__header">
						<div className="scroll-animation scroll-animation__ease-in-from-bottom active">
							<h2 className="a-si h-text-bold">
								<a href="/who-we-are" title="who we are" className="a-link--wline" target="_self">
									{section.smallTitle}
								</a>
							</h2>
						</div>
						<div className="scroll-animation scroll-animation__ease-in-from-bottom active">
							<h3 className="a-title-40 ">
								{section.title}
							</h3>
						</div>
					</header>
            {section.buttonSlug &&
              <div className="m-cwe-images__content">
                <div className="m-cwe-images__content-top scroll-animation scroll-animation__ease-in-from-bottom active">
                  <div className="a-text-20">
                    <p>{section.text}</p>
                    <p>&nbsp;</p>
                  </div>
                  <a href={section.buttonSlug} target="_self" title="Get to know us" className="a-bt">
                    {section.buttonText}
                  </a>
                </div>
              </div>
            }
					</div>
				</div>
			</div>
		</section>
	</div>
)

export default ContentfulTextWithMultipleImages
