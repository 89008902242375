import React from "react"

const ContentfulFeaturedText = ({ section }) => (
	<section className="m-centered-h">
		<div className="container">
			<div className="row">
				<div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3">
					<div className="scroll-animation scroll-animation__ease-in-from-bottom active">
						<h2 className="a-si h-text-bold">
							{section.title}
						</h2>
					</div>
					<div className="scroll-animation scroll-animation__ease-in-from-bottom active">
						<h3 className="a-title-40 ">
							{section.text}
						</h3>
					</div>
				</div>
			</div>
		</div>
	</section>
)

export default ContentfulFeaturedText
