import React from "react"

const ContentfulMainIntro = ({ section }) => (
	<div>
		<section className="m-main-intro">
			<div className="container">
				<div className="row">
					<div className="col-12 col-md-8 offset-md-2">
						<div className="scroll-animation scroll-animation__ease-in-from-bottom active">
							<h2 className="a-si h-text-bold">
								{section.heading}
							</h2>
						</div>
						<div className="scroll-animation scroll-animation__ease-in-from-bottom active">
							<h3 className="a-title-44 h-text-light">
								{section.text}
							</h3>
						</div>
						<a href={section.buttonSlug} target="_self" title={section.buttonText} className="a-bt">
							{section.buttonText}
						</a>
					</div>
				</div>
			</div>
		</section>
	</div>
)

export default ContentfulMainIntro
