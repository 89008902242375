import React, { useContext } from "react"
import { GlobalDispatchContext } from "../context/GlobalContextProvider"

const ContentfulCta = ({ section }) => {
	const dispatch = useContext(GlobalDispatchContext)

	return (
		<section className="m-cta">
			<div className="container">
				<div className="row">
					<header className="m-cta__header col-12 col-sm-7 offset-sm-3 col-md-7 offset-md-0 col-lg-7 col-xl-7 offset-lg-1 scroll-animation scroll-animation__ease-in-from-bottom active">
						<h3 className="a-title-60 h-text-light">
							{section.text}
						</h3>
					</header>
					<div className="col-12 col-sm-7 offset-sm-3 col-md-5 offset-md-0 col-lg-3 col-xl-3 h-text-align-right@md m-cta__button">
						<button
							className="js-modal__bt-open-form a-bt cta_button"
							onClick={() => {
								dispatch({ type: "TOGGLE_MODAL" })
							}}
						>
							<span>
								{section.buttonText}
							</span>
						</button>
					</div>
				</div>
			</div>
		</section>
	)
}

export default ContentfulCta
