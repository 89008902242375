import React from "react"

const ContentfulImageList = ({ section }) => (
	<div>
		<section className="m-g-with-l m-g-with-l--">
			<header className="container">
					<div className="row">
						<div className="col-12 col-lg-10 offset-lg-1">
								<div className="scroll-animation scroll-animation__ease-in-from-bottom active">
									<h2 className="a-si h-text-bold">
										{section.title}
									</h2>
								</div>
								<div className="m-g-with-l__header-desc scroll-animation scroll-animation__ease-in-from-bottom active">
									<div className="a-text-22"> We have strong partnerships with the world's top tech companies. </div>
								</div>
						</div>
					</div>
			</header>
			<div className="m-g-with-l__items h-text-align-center container">
					<div className="row">
						<div className="m-g-with-l__items-col col-12 col-lg-10 offset-lg-1 scroll-animation scroll-animation__children-ease-in-from-bottom active">
								{section.images.map((image) => (
									<a key={image.id} href="/" className="m-g-with-l__item m-g-with-l__item--link" title="AWS" target="_self">
										<img className="b-lazy b-loaded" alt={image.title} src={image.file.url} />
									</a>
								))}
						</div>
					</div>
			</div>
			<footer className="container">
					<div className="row">
						<div className="m-g-with-l__footer-desc col-md-6 col-lg-5 offset-lg-1 scroll-animation scroll-animation__ease-in-from-bottom active">
								<div className="a-text-22">{section.description}</div>
						</div>
            {section.buttonSlug &&
              <div className="m-g-with-l__button col-12 col-md-6 col-lg-4 offset-lg-1 h-text-align-right@md">
                  <a href={section.buttonSlug} target="_self" title="View all" className="a-bt a-bt--@md-align-vertically">
                    {section.buttonText}
                  </a>
              </div>
            }
					</div>
			</footer>
		</section>
	</div>
)

export default ContentfulImageList
