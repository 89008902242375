import React from "react"
import Img from "gatsby-image"

const ContentfulPageHeader = ({ section }) => (
	<header className="m-header" style={{backgroundColor: '#000', height: section.fullHeight ? '70rem' : '40rem'}}>
    {section.backgroundImage && 
		  <Img fluid={section.backgroundImage.fluid} className="m-header__bg h-abs-bg" style={{position: 'absolute', opacity: 0.6}}/>
    }
		<div className="container">
			<div className="row">
				<div className="col-12 col-md-11 col-lg-7">
					<div className="m-header__top scroll-animation scroll-animation__mask-animation active">
						<h1 className="m-header__title"><span>{section.heading}</span></h1>
					</div>
				</div>
				<div className="col-12">
					<div className="m-header__desc">
						<h2 className="scroll-animation scroll-animation__ease-in-from-bottom active">
							{section.subText}
						</h2>
					</div>
				</div>
			</div>
		</div>
	</header>
)

export default ContentfulPageHeader
