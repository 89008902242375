import React from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const ContentfulFeaturedText = ({ section }) => (
	<section className="rich-content">
		<div className="container">
			<div className="row">
				<div className="col-12">
          { documentToReactComponents(section.childContentfulRichContentContentRichTextNode.json) }
				</div>
			</div>
		</div>
	</section>
)

export default ContentfulFeaturedText
